// ** MUI Imports
import { Theme } from '@mui/material';

declare module '@mui/material' {
    interface TypographyVariants {
        h0: React.CSSProperties;
        p1: React.CSSProperties;
        p2: React.CSSProperties;
        p3: React.CSSProperties;
        p4: React.CSSProperties;
        h7: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        h0?: React.CSSProperties;
        p1?: React.CSSProperties;
        p2?: React.CSSProperties;
        p3?: React.CSSProperties;
        p4?: React.CSSProperties;
        h7?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        h0: true;
        p1: true;
        p2: true;
        p3: true;
        p4: true;
        h7: true;
    }
}

const typography = (theme: Theme) => {
    return {
        MuiTypography: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    fontFamily: [
                        'Inter',
                        'sans-serif',
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                    ].join(','),
                },
                gutterBottom: {
                    marginBottom: theme.spacing(2),
                },
            },
        },
    };
};

export default typography;
