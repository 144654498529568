// ** MUI Imports
import { Theme } from '@mui/material';

// ** Overrides Imports
import accordion from './accordion';
import autocomplete from './autocomplete';
import badge from './badge';
import button from './button';
import card from './card';
import chip from './chip';
import container from './container';
import inputLabel from './inputLabel';
import list from './list';
import menu from './menu';
import modal from './modal';
import pagination from './pagination';
import radio from './radio';
import select from './select';
import svgIcon from './svgIcon';
import table from './table';
import tabs from './tabs';
import tooltip from './tooltip';
import tree from './tree';
import typography from './typography';

const components = (theme: Theme) => {
    return {
        ...accordion(theme),
        ...autocomplete(theme),
        ...badge(),
        ...button(theme),
        ...card(),
        ...chip(theme),
        ...container(theme),
        ...inputLabel(theme),
        ...list(theme),
        ...menu(theme),
        ...modal(),
        ...pagination(theme),
        ...radio(theme),
        ...select(theme),
        ...svgIcon(),
        ...table(),
        ...tabs(theme),
        ...tooltip(theme),
        ...tree(theme),
        ...typography(theme),
    };
};

export default components;
