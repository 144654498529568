import { alpha, Theme } from '@mui/material';

const chip = (theme: Theme) => {
    return {
        MuiChip: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(0, 2),
                    height: theme.spacing(8),
                    borderRadius: 20,
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.neutral.main : theme.palette.background.paper,
                    fontSize: '14px',
                    fontWeight: 600,

                    '& .MuiChip-label': {
                        color: theme.palette.mode === 'light' ? theme.palette.cta.main : theme.palette.cta.light,
                    },
                    '& .MuiChip-labelSmall': {
                        fontSize: '12px',
                    },
                    '&.MuiChip-sizeSmall': {
                        padding: '0 2px',
                        height: theme.spacing(5),
                        borderRadius: '9px',
                    },
                    '& .MuiChip-labelLarge': {
                        fontSize: '18px',
                    },
                    '&.MuiChip-sizeLarge': {
                        padding: theme.spacing(0, 2),
                        height: theme.spacing(12),
                        borderRadius: 28,
                    },
                    '&.MuiChip-clickable': {
                        backgroundColor:
                            theme.palette.mode === 'light'
                                ? alpha(theme.palette.cta.main, 0.07)
                                : alpha(theme.palette.common.white, 0.07),
                    },
                    '&.MuiChip-clickable:hover': {
                        backgroundColor: alpha(theme.palette.cta.main, 0.15),
                    },
                    '&.MuiChip-inverted': {
                        backgroundColor: alpha(theme.palette.common.white, 0.07),
                        '& .MuiChip-label': {
                            color: theme.palette.common.white,
                        },
                    },
                    '&.MuiChip-inverted:hover': {
                        backgroundColor: alpha(theme.palette.common.white, 0.2),
                    },
                    '&.Mui-focusVisible': {
                        boxShadow: theme.shadows[24],
                        backgroundColor: theme.palette.neutral.dark,
                    },
                    '&.MuiChip-inverted.Mui-focusVisible': {
                        boxShadow: theme.shadows[24],
                        backgroundColor: theme.palette.secondary.light,
                    },
                    '&.MuiChip-colorPrimary': {
                        backgroundColor:
                            theme.palette.mode === 'light'
                                ? theme.palette.text.primary
                                : theme.palette.background.paper,
                    },
                    '&.MuiChip-colorError': {
                        backgroundColor: theme.palette.error.main,
                        '& .MuiChip-label': {
                            color: theme.palette.error.contrastText,
                        },
                    },
                    '&.MuiChip-colorPrimary .MuiChip-label': {
                        color: theme.palette.common.white,
                    },
                    '&.MuiChip-colorPrimary.MuiChip-clickable:hover': {
                        backgroundColor: theme.palette.secondary.dark,
                    },
                    '&.MuiChip-colorPrimary.Mui-focusVisible': {
                        boxShadow: theme.shadows[24],
                        backgroundColor: theme.palette.secondary.main,
                    },
                },
            },
        },
    };
};

export default chip;
