import { datadogLogs } from '@datadog/browser-logs';
import { useFetcher } from '@remix-run/react';
import { useEffect } from 'react';
import globalConfig from '~/configs/global';
import {
    disableHubspotTracking,
    disablePendoTracking,
    enableHubspotTracking,
    enablePendoTracking,
    initializeGtm,
    onGtmConsentChange,
} from '../track';
import { useCurrentUser } from '../user';

export const useAnalytics = () => {
    const fetcher = useFetcher<{ success: boolean }>();
    const currentUser = useCurrentUser();

    const enableTracking = ({ setCookie }: { setCookie: boolean }) => {
        datadogLogs.setTrackingConsent('granted');

        if (globalConfig.featureFlags.isErrorTrackingEnvironment) {
            window.DD_LOGS.onReady(function () {
                if (currentUser.signedIn && currentUser.user.id) {
                    window.DD_LOGS.setGlobalContextProperty('usr', { id: currentUser.user.id });
                }
            });
        }

        if (globalConfig.featureFlags.isAnalyticsEnvironment) {
            initializeGtm(currentUser);
            onGtmConsentChange('acceptAll');
            enableHubspotTracking(currentUser);
            if (currentUser.signedIn) {
                enablePendoTracking(currentUser);
            }
        }

        if (setCookie) {
            fetcher.submit(
                {
                    onlyNecessary: false,
                    acceptAll: true,
                },
                { method: 'post', action: '/enable-analytics' },
            );
        }
    };

    const disableTracking = ({ setCookie }: { setCookie: boolean }) => {
        datadogLogs.setTrackingConsent('not-granted');
        if (window.DD_LOGS) {
            window.DD_LOGS.setGlobalContextProperty('user_id', 'consent-not-granted');
        }

        if (globalConfig.featureFlags.isAnalyticsEnvironment) {
            onGtmConsentChange('onlyNecessary');

            disableHubspotTracking();
            disablePendoTracking();
        }

        if (setCookie) {
            // Set cookie correctly
            fetcher.submit(
                {
                    onlyNecessary: true,
                    acceptAll: false,
                },
                { method: 'post', action: '/enable-analytics' },
            );
        }
    };

    const updateCookieExpiryGa4 = (): void => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'updateCookieExpiryGa4' });
    };

    useEffect(() => {
        if (fetcher.data?.success) {
            updateCookieExpiryGa4();
        }
    }, [fetcher.data]);

    return {
        enableTracking,
        disableTracking,
        analyticsFetcher: fetcher,
    };
};
