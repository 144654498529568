import { Theme, alpha } from '@mui/material';

const tree = (theme: Theme) => {
    return {
        MuiSimpleTreeView: {
            styleOverrides: {
                root: {
                    '& .MuiTreeItem-content': {
                        minHeight: '51px',
                        borderBottom: theme.border.thin,
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.common.black, 0.04),
                        },
                        '&.Mui-focused': {
                            backgroundColor: alpha(theme.palette.common.black, 0.04),
                        },
                        '&.Mui-selected .MuiTreeItem-label': {
                            fontWeight: 600,
                        },
                        '& .MuiTreeItem-label': {
                            paddingTop: '13px',
                            paddingBottom: '13px',
                        },
                        '&.Mui-expanded .MuiTreeItem-label': {
                            background: 'transparent',
                        },
                        '& .MuiTreeItem-iconContainer': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '34px',
                            height: '34px',
                            marginRight: '0',
                            borderRadius: theme.radius.all,
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.cta.main, 0.05),
                            },
                            '& svg': {
                                color: theme.palette.cta.main,
                                fontSize: 14,
                            },
                        },
                    },
                },
            },
        },
    };
};

export default tree;
