import { Backdrop, Stack, Typography, useTheme } from '@mui/material';

import React, { useState } from 'react';
import Button from '~/components/atoms/Button';
import CircularProgress from '~/components/atoms/CircularProgress';
import FrontContainer from '~/components/molecules/FrontContainer';
import { useAnalytics } from '~/utils/hooks/useAnalytics';

export type ConsentLevels = 'acceptAll' | 'onlyNecessary';

const DataCollectionConsent: React.FC = () => {
    const theme = useTheme();

    const { enableTracking, disableTracking, analyticsFetcher } = useAnalytics();

    const [onlyNecessaryButtonClicked, setOnlyNecessaryButtonClicked] = useState<boolean>(false);
    const [acceptAllButtonClicked, setAcceptAllButtonClicked] = useState<boolean>(false);

    const handleSubmit = (target: HTMLButtonElement) => {
        if (target.name === 'acceptAll') {
            enableTracking({ setCookie: true });
        }

        if (target.name === 'onlyNecessary') {
            disableTracking({ setCookie: true });
        }
    };

    return (
        <Backdrop open={true} sx={{ zIndex: theme.zIndex.appBar - 1 }}>
            <Stack
                sx={{
                    zIndex: theme.zIndex.appBar,
                    position: 'fixed',
                    width: '100%',
                    bottom: 0,
                    pointerEvents: 'none',
                }}
            >
                <FrontContainer maxWidth="xxl" sx={{ alignItems: 'flex-end', pb: { xs: 6, sm: 8, md: 12 } }}>
                    <Stack
                        sx={{
                            p: 6,
                            maxWidth: '600px',
                            transition: 'transform 0.5s',
                            boxShadow: theme.shadows[5],
                            borderRadius: theme.radius.allLarge,
                            backgroundColor: theme.palette.background.paper,
                            pointerEvents: 'auto',
                        }}
                    >
                        <Typography variant="h5" component="h2" sx={{ mb: 4 }}>
                            Dine data – ditt valg
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 4 }}>
                            Våre nettsider benytter informasjonskapsler (cookies) og brukerdata til analyse og noen
                            funksjoner. Les mer om hva slags data vi bruker, hvordan vi administrerer dem og dine
                            rettigheter i&nbsp;
                            <Button variant="link" href="/personvern" tabIndex={1}>
                                cookie-erklæringen
                            </Button>
                            &nbsp;vår.
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 8 }}>
                            Du kan velge å kun ha nødvendige informasjonskapsler og brukerdata, men du kan også velge å
                            godta alle. Slik hjelper du oss med å finne de riktige bøkene og tilpasse tjenesten for deg!
                        </Typography>
                        <Stack
                            sx={{
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                flexDirection: 'column-reverse',
                                [theme.breakpoints.up('sm')]: {
                                    flexDirection: 'row',
                                    width: '65%',
                                    marginLeft: 'auto',
                                },
                            }}
                        >
                            <Button
                                center
                                variant="primary"
                                sx={{
                                    order: 1,
                                    width: '100%',
                                    mb: 3,
                                    [theme.breakpoints.up('sm')]: {
                                        order: 0,
                                        width: 'auto',
                                        mr: 2,
                                        mb: 0,
                                    },
                                }}
                                name="acceptAll"
                                onClick={(ev) => {
                                    handleSubmit(ev.target as HTMLButtonElement);
                                    setAcceptAllButtonClicked(true);
                                }}
                                disabled={analyticsFetcher.state !== 'idle'}
                                startIcon={
                                    analyticsFetcher.state !== 'idle' &&
                                    acceptAllButtonClicked && (
                                        <CircularProgress size={18} sx={{ color: theme.palette.cta.main }} />
                                    )
                                }
                                tabIndex={1}
                                data-cy="cy-data-collection-consent-accept-all"
                            >
                                Godta alle
                            </Button>
                            <Button
                                center
                                variant="secondary"
                                sx={{
                                    order: 0,
                                    width: '100%',
                                    [theme.breakpoints.up('sm')]: {
                                        order: 1,
                                        width: 'auto',
                                    },
                                }}
                                name="onlyNecessary"
                                onClick={(ev) => {
                                    handleSubmit(ev.target as HTMLButtonElement);
                                    setOnlyNecessaryButtonClicked(true);
                                }}
                                disabled={analyticsFetcher.state !== 'idle'}
                                startIcon={
                                    analyticsFetcher.state !== 'idle' &&
                                    onlyNecessaryButtonClicked && (
                                        <CircularProgress size={18} sx={{ color: theme.palette.cta.main }} />
                                    )
                                }
                                tabIndex={1}
                            >
                                Kun nødvendige
                            </Button>
                        </Stack>
                    </Stack>
                </FrontContainer>
            </Stack>
        </Backdrop>
    );
};
export default DataCollectionConsent;
