import { Theme } from '@mui/material';
import { ChevronDown as IconChevronDown } from 'react-feather';

const select = (theme: Theme) => {
    return {
        MuiSelectBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: IconChevronDown,
            },
            styleOverrides: {
                root: {
                    '& .MuiSelect-icon': {
                        color: theme.palette.cta.main,
                        top: 'calc(50% - 12px)',
                        right: '17px',
                    },
                    paddingLeft: theme.spacing(4),
                    backgroundColor: theme.palette.background.paper,
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.cta.main,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.cta.main,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderRadius: theme.radius.all,
                    border: theme.border.medium,
                },
            },
        },
    };
};

export default select;
