// ** Theme Type Import

const base = 4;
const large = 8;
const xLarge = 18;

const radius = {
    all: `${base}px ${base}px ${base}px ${base}px`,
    allBottom: `0px 0px ${base}px ${base}px`,
    allLarge: `${large}px ${large}px ${large}px ${large}px`,
    allLargeBottom: `0px 0px ${large}px ${large}px`,
    allLargeRight: `0px ${large}px ${large}px 0px`,
    allXLarge: `${xLarge}px ${xLarge}px ${xLarge}px ${xLarge}px`,
    allXLargeTop: `${xLarge}px ${xLarge}px 0px 0px`,
};

export default radius;
