import { Palette, ThemeOptions, alpha } from '@mui/material';

const shadows = (palette: Palette): ThemeOptions['shadows'] => {
    return [
        'none',
        `0px 1px 4px 1px ${alpha(palette.grey[900] || '#000', 0.1)}`,
        `0px 2px 6px 1px ${alpha(palette.grey[900] || '#000', 0.1)}`,
        `0px 2px 6px 3px ${alpha(palette.grey[900] || '#000', 0.1)}`,
        `0px 2px 6px 5px ${alpha(palette.grey[900] || '#000', 0.07)}`,
        `0px 2px 6px 6px ${alpha(palette.grey[900] || '#000', 0.05)}`,
        `0px 2px 6px 7px ${alpha(palette.grey[900] || '#000', 0.04)}`,
        '0px 4px 5px -2px rgba(58, 53, 65, 0.2), 0px 7px 10px 1px rgba(58, 53, 65, 0.14), 0px 2px 16px 1px rgba(58, 53, 65, 0.12)',
        '0px 5px 5px -3px rgba(58, 53, 65, 0.2), 0px 8px 10px 1px rgba(58, 53, 65, 0.14), 0px 3px 14px 2px rgba(58, 53, 65, 0.12)',
        '0px 5px 6px -3px rgba(58, 53, 65, 0.2), 0px 9px 12px 1px rgba(58, 53, 65, 0.14), 0px 3px 16px 2px rgba(58, 53, 65, 0.12)',
        '0px 6px 6px -3px rgba(58, 53, 65, 0.2), 0px 10px 14px 1px rgba(58, 53, 65, 0.14), 0px 4px 18px 3px rgba(58, 53, 65, 0.12)',
        '0px 6px 7px -4px rgba(58, 53, 65, 0.2), 0px 11px 15px 1px rgba(58, 53, 65, 0.14), 0px 4px 20px 3px rgba(58, 53, 65, 0.12)',
        '0px 7px 8px -4px rgba(58, 53, 65, 0.2), 0px 12px 17px 2px rgba(58, 53, 65, 0.14), 0px 5px 22px 4px rgba(58, 53, 65, 0.12)',
        '0px 7px 8px -4px rgba(58, 53, 65, 0.2), 0px 13px 19px 2px rgba(58, 53, 65, 0.14), 0px 5px 24px 4px rgba(58, 53, 65, 0.12)',
        '0px 7px 9px -4px rgba(58, 53, 65, 0.2), 0px 14px 21px 2px rgba(58, 53, 65, 0.14), 0px 5px 26px 4px rgba(58, 53, 65, 0.12)',
        '0px 8px 9px -5px rgba(58, 53, 65, 0.2), 0px 15px 22px 2px rgba(58, 53, 65, 0.14), 0px 6px 28px 5px rgba(58, 53, 65, 0.12)',
        '0px 8px 10px -5px rgba(58, 53, 65, 0.2), 0px 16px 24px 2px rgba(58, 53, 65, 0.14), 0px 6px 30px 5px rgba(58, 53, 65, 0.12)',
        '0px 8px 11px -5px rgba(58, 53, 65, 0.2), 0px 17px 26px 2px rgba(58, 53, 65, 0.14), 0px 6px 32px 5px rgba(58, 53, 65, 0.12)',
        '0px 9px 11px -5px rgba(58, 53, 65, 0.2), 0px 18px 28px 2px rgba(58, 53, 65, 0.14), 0px 7px 34px 6px rgba(58, 53, 65, 0.12)',
        '0px 9px 12px -6px rgba(58, 53, 65, 0.2), 0px 19px 29px 2px rgba(58, 53, 65, 0.14), 0px 7px 36px 6px rgba(58, 53, 65, 0.12)',
        '0px 0px 0px 6px rgba(255,242,79,1)',
        `inset 0px 0px 0px 2px  ${alpha('#602CE5', 0.7)}`,
        '0px 0px 0px 3px rgba(255,255,255,0)',
        '0px 0px 0px 3px rgba(255,255,255,0.8)',
        `0px 0px 0px 1px white, 0px 0px 0px 3px  ${alpha('#602CE5', 0.7)}`,
    ];
};
export default shadows;
