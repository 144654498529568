const card = () => {
    return {
        MuiCard: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    overflow: 'visible',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    borderRadius: 0,
                    alignItems: 'flex-start',
                    border: 'none',
                    textAlign: 'left',
                    padding: '0',
                    textDecoration: 'none',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    '&:last-child': {
                        paddingBottom: 0,
                    },
                },
            },
        },
    };
};

export default card;
