import { ThemeOptions, Palette, PaletteMode } from '@mui/material';

import palette from './palette';
import border from './border';
import radius from './radius';
import spacing from './spacing';
import shadows from './shadows';
import breakpoints from './breakpoints';
import transitions from './transitions';

interface CustomThemeOptions extends ThemeOptions {
    mode: PaletteMode;
}

const getThemeOptions = (settings: CustomThemeOptions): CustomThemeOptions => {
    const { mode } = settings;

    const defaultPalette: Palette = palette(mode);

    return {
        mode,
        palette: defaultPalette,
        spacing,
        typography: {
            fontFamily: [
                'Inter',
                'sans-serif',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
            ].join(','),
        },
        breakpoints: breakpoints(),
        border: border(defaultPalette),
        radius,
        shadows: shadows(defaultPalette),
        transitions,
    };
};

export default getThemeOptions;
