import { Theme } from '@mui/material';

const button = (theme: Theme) => {
    return {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
                // disableElevation: true,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    paddingBottom: '0',
                    paddingTop: '0',
                    textTransform: 'none',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                    justifyContent: 'flex-start',
                    borderRadius: '0',
                    lineHeight: '1.2',
                    '&:hover, &.Mui-hoverVisible': {
                        backgroundColor: 'transparent',
                    },
                    '&.MuiButton-sizeSmall .MuiButton-startIcon>*:nth-of-type(1)': {
                        fontSize: '14px',
                    },
                    '&.MuiButton-sizeMedium .MuiButton-startIcon>*:nth-of-type(1)': {
                        fontSize: '18px',
                    },
                    '&.MuiButton-sizeLarge .MuiButton-startIcon>*:nth-of-type(1)': {
                        fontSize: '20px',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    borderRadius: theme.radius.all,
                    '&.Mui-focusVisible': {
                        boxShadow: theme.shadows[24],
                    },
                    '&.Mui-disabled .MuiSvgIcon-root': {
                        color: theme.palette.grey[400],
                    },
                },
            },
        },
    };
};

export default button;
