import { Theme } from '@mui/material';

const typography = (theme: Theme) => {
    return {
        h0: {
            fontWeight: 800,
            fontSize: 58,
            lineHeight: 1.1,
        },
        h1: {
            fontWeight: 800,
            fontSize: 48,
            lineHeight: 1.2,
        },
        h2: {
            fontWeight: 800,
            fontSize: 34,
            lineHeight: 1.2,
        },
        h3: {
            fontWeight: 800,
            fontSize: 30,
            lineHeight: 1.2,
        },
        h4: {
            fontWeight: 700,
            fontSize: 28,
            lineHeight: 1.3,
        },
        h5: {
            fontWeight: 700,
            fontSize: 22,
            lineHeight: 1.32,
        },
        h6: {
            fontWeight: 700,
            fontSize: 18,
            lineHeight: 1.5,
        },
        h7: {
            fontWeight: 700,
            fontSize: 14,
            lineHeight: 1.5,
        },
        p1: {
            fontWeight: 500,
            fontSize: 18,
            lineHeight: 1.4,
        },
        p2: {
            fontWeight: 500,
            fontSize: 16,
            lineHeight: 1.4,
        },
        p3: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 1.4,
        },
        p4: {
            fontWeight: 500,
            fontSize: 12,
            lineHeight: 1.4,
        },
        caption: {
            fontWeight: 500,
            fontSize: 14,
            color: theme.palette.text.secondary,
            lineHeight: 1.4,
        },
        button: {
            fontWeight: 600,
            letterSpacing: '0.3px',
            lineHeight: 1,
        },
    };
};

export default typography;
