const modal = () => {
    return {
        MuiModal: {
            styleOverrides: {
                root: {
                    '& .MuiStack-root:focus-visible': {
                        outline: 'none',
                    },
                },
            },
        },
    };
};

export default modal;
