import { alpha, Theme } from '@mui/material';

const pagination = (theme: Theme) => {
    return {
        MuiPagination: {
            styleOverrides: {
                root: {
                    '.MuiPaginationItem-page': {
                        backgroundColor: 'transparent',
                        border: '1px solid transparent',
                        color: theme.palette.mode === 'light' ? theme.palette.cta.main : theme.palette.text.primary,
                        borderRadius: theme.radius.all,
                        fontWeight: '700',
                        ':hover': {
                            backgroundColor: alpha(theme.palette.cta.main, 0.05),
                        },
                        '&.Mui-focusVisible': { boxShadow: theme.shadows[24], backgroundColor: 'transparent' },
                    },
                    '.MuiPaginationItem-page.Mui-selected': {
                        backgroundColor: 'transparent',
                        color: theme.palette.mode === 'light' ? theme.palette.cta.main : theme.palette.text.primary,
                        borderRadius: theme.radius.all,
                        border: `2px solid ${theme.palette.cta.main}`,
                        fontWeight: '700',
                        ':hover': {
                            backgroundColor: alpha(theme.palette.cta.main, 0.05),
                        },
                        '&.Mui-focusVisible': { boxShadow: theme.shadows[24], backgroundColor: 'transparent' },
                    },
                    '.MuiPaginationItem-previousNext': {
                        margin: theme.spacing(0, 6),
                        borderRadius: theme.radius.all,
                        backgroundColor: 'transparent',
                        '.MuiPaginationItem-icon': {
                            color: theme.palette.cta.main,
                        },
                        ':hover': {
                            backgroundColor: alpha(theme.palette.cta.main, 0.05),
                        },
                        '&.Mui-focusVisible': { boxShadow: theme.shadows[20] },
                    },
                    '.MuiPaginationItem-previousNext.MuiPaginationItem-sizeLarge': {
                        height: '48px',
                        minWidth: '48px',
                        '.MuiPaginationItem-icon': {
                            width: '28px',
                            height: '28px',
                        },
                        '&.Mui-focusVisible': { boxShadow: theme.shadows[20] },
                    },
                },
            },
        },
    };
};

export default pagination;
