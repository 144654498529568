import { Theme } from '@mui/material';

const inputLabel = (theme: Theme) => {
    return {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    lineHeight: '1',
                    marginBottom: theme.spacing(2),
                    fontSize: 13,
                    fontWeight: 600,
                },
            },
        },
    };
};

export default inputLabel;
