import { Theme } from '@mui/material';

const container = (theme: Theme) => {
    return {
        MuiContainer: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(0, 6),
                },
                maxWidthXl: {
                    padding: theme.spacing(0, 8),
                },
            },
        },
    };
};

export default container;
