const table = () => {
    return {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    borderRadius: 0,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                    padding: 0,
                },
            },
        },
    };
};

export default table;
