import { alpha, Theme } from '@mui/material';

const menu = (theme: Theme) => {
    return {
        MuiMenu: {
            styleOverrides: {
                root: {
                    '& .MuiPaper-elevation': {
                        boxShadow: theme.shadows[5],
                    },
                    '& .MuiPaper-root': {
                        borderRadius: theme.radius.allLarge,
                    },
                    '& .MuiMenuItem-root:hover': {
                        backgroundColor:
                            theme.palette.mode === 'light'
                                ? alpha(theme.palette.cta.main, 0.05)
                                : alpha(theme.palette.common.white, 0.05),
                        borderRadius: theme.radius.all,
                    },
                },
            },
        },
    };
};

export default menu;
