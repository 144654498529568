import { Palette, alpha } from '@mui/material';

const border = (palette: Palette) => {
    const color = palette.mode === 'light' ? alpha(palette.common.black, 0.1) : alpha(palette.common.white, 0.1);

    return {
        thin: `1px solid ${color}`,
        medium: `2px solid ${color}`,
    };
};

export default border;
