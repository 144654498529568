// ** MUI
import { ThemeProvider, alpha, createTheme, responsiveFontSizes } from '@mui/material';

// ** Types
import CustomThemeProviderProps from './types';
// ** Theme Config
import themeConfig from '~/configs/theme';

// ** Theme Override Imports
import components from './components';
import typography from './typography';

// ** Theme
import getThemeOptions from './themeOptions';

// ** Fonts
// 400
import InterRegularWoff from '~/assets/fonts/inter/Inter-Regular.woff';
import InterRegularWoff2 from '~/assets/fonts/inter/Inter-Regular.woff2';

import InterItalicWoff from '~/assets/fonts/inter/Inter-Italic.woff';
import InterItalicWoff2 from '~/assets/fonts/inter/Inter-Italic.woff2';

// 500
// import InterMediumWoff from '~/assets/fonts/inter/Inter-Medium.woff';

// 600
import InterSemiBoldWoff from '~/assets/fonts/inter/Inter-SemiBold.woff';
import InterSemiBoldWoff2 from '~/assets/fonts/inter/Inter-SemiBold.woff2';

// 700
import InterBoldWoff from '~/assets/fonts/inter/Inter-Bold.woff';
import InterBoldWoff2 from '~/assets/fonts/inter/Inter-Bold.woff2';

// 800
// import InterMediumWoff from '~/assets/fonts/inter/Inter-ExtraBold.woff';

// 900
import InterBlackWoff from '~/assets/fonts/inter/Inter-Black.woff';
import InterBlackWoff2 from '~/assets/fonts/inter/Inter-Black.woff2';

const CustomThemeProvider = (props: CustomThemeProviderProps) => {
    const { colorMode, children } = props;

    const themeOptions = getThemeOptions({ mode: colorMode });

    let theme = createTheme(themeOptions);

    theme = createTheme(theme, {
        components: {
            MuiCssBaseline: {
                styleOverrides: `
                   @font-face {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-display: swap;
                        src: url(${InterRegularWoff2}) format('woff2'),
                            url(${InterRegularWoff}) format('woff');
                    }
                    @font-face {
                        font-family: 'Inter';
                        font-style: italic;
                        font-weight: 400;
                        font-display: swap;
                        src: url(${InterItalicWoff2}) format('woff2'),
                            url(${InterItalicWoff}) format('woff');
                    }
                    @font-face {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 600;
                        font-display: swap;
                        src: url(${InterSemiBoldWoff2}) format('woff2'),
                            url(${InterSemiBoldWoff}) format('woff');
                    }
                    @font-face {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 700;
                        font-display: swap;
                        src: url(${InterBoldWoff2}) format('woff2'),
                            url(${InterBoldWoff}) format('woff');
                    }
                    @font-face {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 900;
                        font-display: swap;
                        src: url(${InterBlackWoff2}) format('woff2'),
                            url(${InterBlackWoff}) format('woff');
                    }
                    body {
                        background-color: transparent;
                    }
                    .allvit-content p a, .allvit-content li a, p.allvit-content a, ul.allvit-content li a {
                        border-top: 2px solid transparent;
                        border-bottom: 2px solid ${
                            theme.palette.mode === 'light'
                                ? alpha(theme.palette.cta.main, 0.3)
                                : alpha(theme.palette.common.white, 0.5)
                        };
                        font-weight: 600;
                        text-decoration: none;
                        outline: 0;
                        color: ${theme.palette.mode === 'light' ? theme.palette.cta.main : theme.palette.cta.light};
                        transition: border-bottom 0.3s;
                    }
                    .allvit-content p a:hover, .allvit-content li a:hover, p.allvit-content a:hover, ul.allvit-content li a:hover {
                        border-bottom: 2px solid ${
                            theme.palette.mode === 'light' ? theme.palette.cta.main : theme.palette.common.white
                        };
                    }
                    figure {
                        margin: 0;
                    }
                    figure img {
                        display: block;
                    }
                    figure figcaption {
                        margin-top: 10px;
                        font-size: 16px;
                    }
                    ::selection {
                        background: ${alpha(theme.palette.cta.main, 0.15)};
                    }
                    .youtube-video-container {
                        position: relative;
                        overflow: hidden;
                        width: 100%;
                    }
                    .youtube-video-container::after {
                        display: block;
                        content: "";
                        padding-top: 56.25%;
                    }
                    .youtube-video-container iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                `,
            },
            ...components(theme),
        },
        typography: { ...typography(theme) },
    });

    if (themeConfig.responsiveFontSizes) {
        theme = responsiveFontSizes(theme);
    }

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
