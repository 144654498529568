import { Theme } from '@mui/material';

const accordion = (theme: Theme) => {
    return {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    paddingRight: '0',
                    paddingLeft: '0',
                    boxShadow: 'none',
                    borderBottom: theme.border.thin,
                    borderRadius: '0',
                    backgroundImage: 'none',
                    backgroundColor: 'transparent',
                    '& .MuiAccordionDetails-root': {
                        paddingRight: '0',
                        paddingLeft: '0',
                    },
                    '&:before': {
                        display: 'none',
                    },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        color: theme.palette.text.primary,
                    },
                },
            },
        },

        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    paddingRight: '0',
                    paddingLeft: '0',
                    '&.Mui-focusVisible': { boxShadow: theme.shadows[21], backgroundColor: 'transparent' },
                },
            },
        },

        MuiIconAccordion: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    '&:hover': {
                        backgroundColor: theme.palette.grey[100],
                    },
                },
            },
        },
    };
};

export default accordion;
