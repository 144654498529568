const badge = () => {
    return {
        MuiBadge: {
            styleOverrides: {
                badge: {
                    fontWeight: '700',
                },
            },
        },
    };
};

export default badge;
