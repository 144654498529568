import { Theme } from '@mui/material';
import { ChevronDown as IconChevronDown, X as IconX } from 'react-feather';

const autocomplete = (theme: Theme) => {
    const DownIcon = <IconChevronDown color={theme.palette.cta.main} />;
    const ClearIcon = <IconX color={theme.palette.cta.main} />;

    return {
        MuiAutocompleteBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                popupIcon: DownIcon,
                clearIcon: ClearIcon,
            },
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-option.Mui-focusVisible': {
                        backgroundColor: theme.palette.grey[200],
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                        marginRight: '4px',
                    },
                    '& .MuiTextField-root': {
                        height: 'auto',
                    },
                    '& fieldset.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline, & .MuiFormControl-root .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline':
                        {
                            border: 'none',
                        },
                    '& .MuiOutlinedInput-root': {
                        padding: '5px',
                        backgroundColor: theme.palette.background.paper,
                    },
                },
                inputRoot: {
                    borderRadius: theme.radius.all,
                    border: theme.border.medium,
                    paddingLeft: theme.spacing(4),
                    '& .MuiIconButton-root': {
                        right: '6px',
                    },
                    '&:hover': {
                        borderColor: theme.palette.cta.main,
                    },
                    '&.Mui-focused': {
                        borderColor: theme.palette.cta.main,
                    },
                },
            },
        },
    };
};

export default autocomplete;
