const MuiSvgIcon = () => {
    return {
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeSmall: {
                    fontSize: 14,
                },
                fontSizeMedium: {
                    fontSize: 22,
                },
                fontSizeLarge: {
                    fontSize: 30,
                },
            },
        },
    };
};

export default MuiSvgIcon;
