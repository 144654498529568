import { Theme } from '@mui/material';

const tooltip = (theme: Theme) => {
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.text.primary : theme.palette.background.darker,
                    fontSize: 14,
                },
            },
        },
    };
};

export default tooltip;
