import { Theme } from '@mui/material';

const button = (theme: Theme) => {
    return {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
                // disableElevation: true,
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: theme.palette.secondary.dark,
                    },
                },
            },
        },
    };
};

export default button;
