import { Theme } from '@mui/material';

const tabs = (theme: Theme) => {
    return {
        MuiTabs: {
            styleOverrides: {
                root: {
                    overflow: 'visible',
                    '& .MuiTabs-indicator': {
                        height: '3px',
                        borderRadius: '1px',
                        backgroundColor: theme.palette.cta.main,
                    },
                    '& .MuiTabs-scroller': {
                        display: 'inline-flex',
                    },
                    '& .MuiTabScrollButton-root': {
                        width: theme.spacing(6),
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    paddingRight: '0',
                    paddingLeft: '0',
                    textTransform: 'none',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                    color: theme.palette.text.primary,
                    minHeight: '52px',
                    minWidth: 'auto',
                    marginRight: theme.spacing(10),
                    '&>.MuiTab-iconWrapper': {
                        marginRight: theme.spacing(3),
                    },
                    '&.Mui-focusVisible': { boxShadow: theme.shadows[21] },
                    '&.Mui-selected': {
                        color: theme.palette.text.primary,
                    },
                },
            },
        },
    };
};

export default tabs;
